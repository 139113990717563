import axios from "axios";
import API from "./api";
import Error from "./error";
export default class Housing {

     static setHousing(id , params) {
        return axios.put(`${API.API}/properties/admin/housings/${id}`, params)
            .then(response =>  response.data?.data).catch((error) => {
                Error.addError(error)
            })
    }
    static createHousing(params) {
        return axios.post(`${API.API}/properties/admin/housings`, params)
            .then(response =>  response.data?.data).catch((error) => {
                Error.addError(error)
            })
    }
    static setHousingApartment(params) {
        return axios.post(`${API.API}/properties/housingapartment_set`, params)
            .then(response =>  response.data?.data).catch((error) => {
                Error.addError(error)
            })
    }

    static getHousingAll(params) {
         return axios.get(`${API.API}/properties/housings`, {params}).then(
             (response) => {
                 return response.data.data  ? response.data.data : {}}
         ).catch((error) => {
             Error.addError(error)
         })
    }

    static getHouse(id) {
         return axios.get(`${API.API}/properties/housings/${id}`).then((response) => response.data.data).catch((error) => {
             Error.addError(error)
         })
    }

    static getHousingApartment(id) {
        return axios.get(`${API.API}/properties/housing_apartments_grid/${id}`).then(
            (response) => {
                return response.data.data }
        ).catch((error) => {
            Error.addError(error)
        })
    }

    static deleteHouse(id) {
        return axios.delete(`${API.API}/properties/admin/housings/${id}`).then(
            (response) => {
                return response.data.data
            }
        ).catch((error) => {
            Error.addError(error)
        })
    }

    static getHouses(id) {
        return axios.get(`${API.API}/properties/get`,{
            params: {
                id
            }
        }).then(
            (response) => {
                return response.data.data
            }
        ).catch((error) => {
            Error.addError(error)
        })
    }

    static getHousesDistrict() {
        return axios.get(`${API.API}/properties/housing_get_by_district`).then(
            (response) => {
                return response.data.data
            }
        ).catch((error) => {
            Error.addError(error)
        })
    }
    //
    // static getApartmentCheck(params) {
    //     return axios.get(`${API.API}/properties/apartment_check`, {
    //         params
    //     } ).then(
    //         (response) => {
    //             return response.data.data
    //         }
    //     ).catch((error) => {
    //         Error.addError(error)
    //     })
    // }

    static getNumbersProperties(id) {
        return axios.get(`${API.API}/properties/housing_apartments_users/${id}`).then(
            (response) => {
                return response.data.data }
        ).catch((error) => {
            Error.addError(error)
        })
    }
    static getListRooms(id, type) {
        return axios.get(`${API.API}/properties/housing_apartments/${id}`, {params: {type}}).then(
            (response) => {
                return response.data.data }
        ).catch((error) => {
            Error.addError(error)
        })
    }
    static getPropertyFraction(params, signal) {
        return axios.get(`${API.API}/properties/property_fraction`, {params, signal}).then(
            (response) => response.data.data || response.data
        ).catch((error) => {
            return Error.addError(error)
        })
    }

    static getHouseSections(id) {
        return axios.get(`${API.API}/properties/housing_section`, {
            params: {
                id
            }
        }).then(
            (response) => response.data.data
        ).catch((error) => {
            return Error.addError(error)
        })
    }

    static getHouseFloors(id, section) {
        return axios.get(`${API.API}/properties/housing_floor`, {
            params: {
                id,
                section
            }
        }).then(
            (response) => response.data.data
        ).catch((error) => {
            return Error.addError(error)
        })
    }


}
