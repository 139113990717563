<template>
    <div class="p-fixed w-100 h-100 bg-popup" :class="parentClassBackground" @click="close">
  </div>
  <div class="p-fixed popup" :class="[{'popup-image': isImage}]">
    <div class="block-popup popup_container p-relative d-flex">
      <div class="w-100" :class="parentClass">
          <div v-if="header">
              <span class="text-gray paragraph_medium">{{additionalHeader}}</span>
              <h4 class="popup__heading mb-1"> {{header}}</h4>
          </div>
          <slot></slot>
      </div>
      <img src="../../../assets/images/button_ozon.svg" @click="close" alt=""
           style="position: absolute; top: 20px; right:14px">

    </div>
  </div>
</template>


<script>
export default {
  name: "PopupMsg",
  emits: ['closePopup'],
    props: {
        header: String,
        additionalHeader: String,
        isImage: {
            type: Boolean, default: false
        },
        parentClass: {
            type: String,
            default: ''
        },
        parentClassBackground: {
            type: String,
            default: ''
        },
    },
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    close() {
      this.$emit('closePopup')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
