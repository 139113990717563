<template>
  <Popup v-if="isOpenConfirm" @close-popup="closeConfirm">
    <Confirm defaultText="Вы уверены, что хотите выйти" @close="closeConfirm" @confirm="logout"></Confirm>
  </Popup>
  <div v-if="isUserMenuOpen === true" style="position:absolute; width: 100vw;height: 100vh;z-index: 1;"
       @click="openUserMenu()"></div>
  <div :class="{ 'flex-md-row': routes.length && !$route.meta?.hideMenu }" class="d-flex flex-column  bg-main"
       style="min-height: 100vh;">
    <div v-if="routes.length && !$route.meta?.hideMenu">
      <aside class="mr-0 my-md-4 my-0 ml-0 px-0 px-md-4">
        <nav class="navbar navbar-light navbar-expand-lg align-items-start alt-navbar">
          <div class="container p-0 d-flex flex-column">
            <div class="container p-0">
              <div class="row d-md-none align-items-center">
                <div class="col-2 col-md-4 px-3">
                  <button class="navbar-toggler" @click="toggler = !toggler"><span
                      class="sr-only"></span><img
                      alt="Burger" src="./assets/images/Burger.svg"></button>
                </div>
                <div class="col-8 col-md-4 px-3 text-center">
                  <img v-if="imgName" :src="require(`./assets/images/${imgName}.svg`)" alt="logo_cp" height="20">
                  <p v-if="complexName" class="text-white mt-1 mb-0"
                     style="font-size: 14px;font-weight: 600;line-height: 1;">{{ complexName }}</p>
                </div>
                <div class="col-2 px-2">
                  <img v-if="routes.length" alt="Пользователь" class="avatar" src="./assets/images/user-icon.svg"
                       @click.stop="openUserPage">
                </div>

              </div>
            </div>
            <router-link :to="{name:'Home'}"
                         class="navbar-brand d-none d-md-flex align-items-center justify-content-center logo-text" href="#"
                         style="background-color: initial; box-shadow: initial">
              <img v-if="imgName" :src="require(`./assets/images/${imgName}.svg`)" alt="logo_cp" height="15"
                   style="margin-top: -4px;">
              <div v-if="complexName" class="d-flex flex-column navbar-brand__description"><span
                  style="font-size: 14px;font-weight: 600;line-height: 1">{{ complexName }}</span>
              </div>
            </router-link>
            <p class="navbar-nav d-flex flex-column p-0 d-none d-md-flex text-secondary" style="font-size: 12px">
              СОБСТВЕННИК</p>
            <ul class="navbar-nav d-flex flex-column p-0 d-none d-md-flex">
              <li v-for="route in routes" :key="route.name" class="nav-item d-flex align-items-center">
                <router-link :class="{'router-link-active': $route.meta.parent === route.name}" :to="{name: route.name}"
                             class="nav-link text-white d-flex align-items-center">
                  <img :src="require(`./assets/images/${route.src}.svg`)" alt='menu-ico'
                       class="fa-solid fa-bullseye p-1 mr-2" style="border-radius: 8px">
                  {{ route.text }}
                </router-link>
              </li>
            </ul>
            <p v-if="this.isAdminRole" class="navbar-nav d-flex flex-column p-0 d-none d-md-flex text-secondary"
               style="font-size: 12px">ПАНЕЛЬ АДМИНИСТРАТОРА</p>

            <ul class="navbar-nav d-flex flex-column p-0 d-none d-md-flex">
              <li v-for="route in adminRoutes" :key="route.name" class="nav-item d-flex align-items-center">
                <router-link :class="{'router-link-active': $route.meta.parent === route.name}" :to="{name: route.name}"
                             class="nav-link text-white d-flex align-items-center">
                  <img :src="require(`./assets/images/${route.src}.svg`)" alt='menu-ico'
                       class="fa-solid fa-bullseye p-1 mr-2" style="border-radius: 8px">
                  <span>
                         {{ route.text }}
                      </span>

                </router-link>
              </li>
            </ul>

            <div v-show="toggler" class="collapse navbar-collapse flex-column">
              <p class="text-secondary navbar-nav mt-4" style="font-size: 16px">СОБСТВЕННИК</p>

              <ul class="navbar-nav d-flex flex-column p-0">
                <li v-for="route in routes" :key="route.name" class="nav-item d-flex align-items-center">
                  <router-link :class="{'router-link-active': $route.meta.parent === route.name}" :to="{name: route.name}"
                               class="nav-link text-white d-flex align-items-center"
                               @click="toggler = !toggler">
                    <img :src="require(`./assets/images/${route.src}.svg`)" alt='menu-ico'
                         class="fa-solid fa-bullseye p-1 mr-2" style="border-radius: 8px">
                    {{ route.text }}
                  </router-link>
                </li>
              </ul>
              <p v-if="this.isAdminRole" class="navbar-nav text-secondary" style="font-size: 16px">ПАНЕЛЬ
                АДМИНИСТРАТОРА</p>

              <ul v-if="this.isAdminRole" class="navbar-nav d-flex flex-column p-0">
                <li v-for="route in adminRoutes" :key="route.name" class="nav-item d-flex align-items-center">
                  <router-link :class="{'router-link-active': $route.meta.parent === route.name}" :to="{name: route.name}" class="nav-link text-white"
                               @click="toggler = !toggler">
                    <img :src="require(`./assets/images/${route.src}.svg`)" alt='menu-ico'
                         class="fa-solid fa-bullseye p-1 mr-1" style="border-radius: 8px">
                    {{ route.text }}
                  </router-link>
                </li>
              </ul>
              <!--              <button class="btn mb-4 w-100" style="background-color: white;"-->
              <!--                      @click.stop="confirmLogout()">-->
              <!--                Выйти-->
              <!--              </button>-->
            </div>
          </div>
          <div class="navbar-nav d-flex flex-column p-0 d-none d-md-flex w-100">
            <div class="d-flex  align-items-center justify-content-between w-100">
              <div class="d-flex align-items-center pointer ml-0 mr-4" @click.stop=openUserPage()>
                <img alt="Пользователь" class="avatar" src="./assets/images/user-icon.svg">
                <p class="text-white ml-2 mb-0" >
                  {{
                    (this.user.UserInformation.first_name || this.user.UserInformation.last_name) ?
                        (this.user.UserInformation.first_name + ' ' + this.user.UserInformation.last_name) :
                        (getNumberPhone(this.user.number))
                  }}</p>
              </div>
              <div class="p-2 pointer" @click.stop="confirmLogout()">
                <img alt="img-exit" class="img-exit" src="./assets/images/logout-icon.svg"
                    >
              </div>
            </div>

          </div>

        </nav>
      </aside>
    </div>
    <div v-else-if="$route.meta?.hideMenu">
      <nav class="px-4 w-100 py-4 d-flex justify-content-between pointer" style="background: #16263C"
           @click="$router.push({name: 'Login'})">
        <img v-if="imgName" :src="require(`./assets/images/${imgName}.svg`)" alt="logo_cp" class="d-block mx-auto my-auto"
             height="20">
      </nav>
    </div>
    <div class="w-100 scroll-content pb-4">
      <div v-if="routes.length" class="navbar w-nav d-flex flex-wrap align-items-center justify-content-end">
        <div class="main-container nav-container ml-0 mr-0" style="width: 85%">
          <div class="nav-wrapper">
          </div>
        </div>
      </div>

      <div>
        <router-view/>
      </div>
      <div class="p-fixed errors">
        <div v-for="error in error.errors" :key="error" class="content-wrap mb-2 bg-main">
          <div class="d-flex mb-2">
            <h4 class="">Ошибка</h4>
          </div>
          <div class="d-flex">
            <div class="defect-left"></div>
            <p class="headline3 headline_color">{{ error }}</p>
          </div>
        </div>
        <div v-for="success in error.success" :key="success" class="content-wrap bg-main mb-2">
          <div class="d-flex mb-2">
            <h4 class="">{{ success.header }}</h4>
          </div>
          <div class="d-flex">
            <div class="defect-left"></div>
            <p class="headline3 headline_color">{{ success.text }}</p>
          </div>
        </div>

      </div>
    </div>
  </div>

</template>

<script>
import "@/scss/styles.scss";
import {mapState, mapGetters} from 'vuex';
import Login from "./services/login";
import menu from "./helpers/menu";
import Headers from "@/services/headers";
import 'v-calendar/dist/style.css';
import {numberPhone} from "@/helpers/number";
import Popup from "@/components/common/popup/Popup";
import Confirm from "@/components/common/popup/Confirm";

export default {
  components: {Popup, Confirm},
  name: 'App',
  data() {
    return {
      toggler: false,
      isMenuOpen: false,
      isUserMenuOpen: false,
      complexName: '',
      imgName: '',
      isOpenConfirm: false,
    }
  },

  beforeMount() {
    const devices = new RegExp('Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|' +
            'Kindle|Silk|Opera Mini', "i");
    this.$store.commit('setDevice', devices.test(navigator.userAgent));
    window.addEventListener('resize', this.setupResizeListener);
    Headers.getComplexInfo().then(data => {
      if (data.status === 'OK') {
        this.complexName = data.data.name;
        this.imgName = data.data.file_name;
      }
    })
  },

  computed: {
    ...mapState(['user', 'error']),
    ...mapGetters(['isAdminRole']),
    routes: {
      get() {
        return this.user.isAuthenticated ? menu.client.main.filter((item) => !item.hide) : [];
      }
    },
    adminRoutes: {
      get() {
        return this.user.isAuthenticated && this.isAdminRole ? menu.admin.main.filter((item) => !item.hide) : [];
      }
    },
  },

  methods: {
    logout() {
      this.closeConfirm();
      Login.authLogout().then(() => {
        this.$store.dispatch('checkAuthenticated', false);
        const query = {};
        if (this.$route.name === 'Login' || this.$route.name === 'Register' || this.$route.params.id) {
          query.name = this.$route.query.name;
        } else {
          query.name = this.$route.name;
        }
        if (this.$route.name !== 'Login') {
          this.$router.replace({name: 'Login', query})
        }
      })
      this.isMenuOpen = !this.isMenuOpen;
      this.toggler = false
    },
    getNumberPhone(number) {
      return numberPhone(number);
    },
    openUserMenu() {
      if (this.additionallyRoutes.length) {
        this.isUserMenuOpen = !this.isUserMenuOpen
      }
    },
    openUserPage() {
      if (this.toggler) {
        this.toggler = !this.toggler
      }
      this.$router.push({name: 'Home'})
    },
    closeConfirm() {
      this.isOpenConfirm = false;
    },
    confirmLogout() {
      this.isOpenConfirm = true;
    },
    setupResizeListener() {
      this.$store.commit('setResizeScreen')
    }
  },
}
</script>
<style lang="scss">
@import './assets/css/normalize.css';
@import './assets/css/tsg-cdbe32.webflow.css';
@import './assets/css/webflow.css';
@import "~@vueform/multiselect/themes/default.css";


.navbar-collapse {
  @media (min-width: 768px) {
    display: none;
  }
}


</style>
