<template>
    <Popup v-if="isOpenFlatPopup" @close-popup="closePopup"
           :header="`${ isHouseEditing ? 'Редактирование информации о недвижимости' : 'Добавление информации о недвижимости'}`">
        <AddFlat @add="add" :is-editing="isHouseEditing" :current-house="currentHouse"
                 :my-petition-houses="apartments" :is-user-view="isEditing"></AddFlat>
    </Popup>
  <div class="row">
    <div class="col-12 px-2 d-flex justify-content-end">
      <a v-if="apartments.length && !isEditing" class="btn btn-primary" @click="openPopup">
        <i class="fa-solid fa-plus small"></i> Добавить
      </a>
    </div>

  </div>
  <div v-if="apartments.length" class="mt-3">
    <div class="row">
      <div v-for="item in apartments" :key="item.housing.id + item.apa_number || item.apartment?.apa_number"
           class="col-12 col-md-6 col-lg-4 px-2 my-2">
        <div class="property-card">
          <img :src="require(`../../assets/images/${item.type || item.apartment?.type}.svg`)" alt="иконка"
               class="property-card__icon" style="position: absolute;top: 25px">

          <img class="property-card__action" src="../../assets/images/edit.svg"
               style="color: #067acd; position: absolute; right: 15px;top: 28px;"
               @click.stop="editHouse(item)">
          <div class="property-card__section">
            <p class="property-card__title">
              {{ getType(item) }}
            </p>
            <div >
              <p class="tooltip-content">№ {{ item.apa_number || item.apartment.apa_number }}</p>
            </div>
          </div>

          <div class="property-card__section">
            <p class="property-card__section-heading">
              Адрес
            </p>
            <div :aria-label="item.housing.address + ' этаж ' + (item.floor || item.apartment?.floor)" class="tooltip-gray tooltip-text">
              <p class="tooltip-content">{{ item.housing.address }}, этаж {{ item.floor || item.apartment?.floor }}</p>
            </div>
          </div>
          <div class="property-card__section">

            <p class="property-card__section-heading">
              Общая площадь
            </p>
            <div class="property-card__section-description">
              <p>
                {{ (item.sqm || item.apartment?.sqm) ?? 'Не указана' }} <span v-if="item.sqm || item.apartment?.sqm">м<sup>2</sup></span>
              </p>
            </div>
          </div>

          <div class="property-card__section">
            <p class="property-card__section-heading">
              Доля
            </p>
            <div class="property-card__section-description">
              <p>
                {{ (item.fraction || item.apartment?.fraction) ??  'Не указана' }} %
              </p>
            </div>
          </div>

        <div class="property-card__section">
          <p class="property-card__section-heading">
            Голосующая площадь
          </p>
          <div class="property-card__section-description">
            <p>
              <template v-if="(item.sqm || item.apartment?.sqm) && (item.fraction || item.apartment?.fraction)">
                {{ (((item.sqm || item.apartment?.sqm) * (item.fraction || item.apartment?.fraction)) / 100).toFixed(2) }} м<sup>2</sup>
              </template>
              <template v-else>
                Не указана
              </template>
            </p>
          </div>
        </div>
        <div class="property-card__section mb-0">
          <p class="property-card__section-heading">
            Кадастровый номер
          </p>
          <div class="property-card__section-description">
            <p>
              {{ (item.cadastral || item.apartment?.cadastral ) ??  'Не указан' }}
            </p>
          </div>
        </div>
        <div>
        </div>
      </div>
    </div>
  </div>
  </div>

  <div v-if="showEmptyState && !apartments.length" class="mx-auto text-center">
    <EmptyState :is-editing="isEditing" default-text="К cожалению, у вас ещё нет добавленной недвижимости "
                @add-apartment="openPopup"></EmptyState>
  </div>


</template>

<script>

import {mapState} from "vuex";
import Popup from "../../components/common/popup/Popup";
import AddFlat from "../../components/common/home/AddFlat";
import User from "../../services/user";
import EmptyState from "../../components/common/EmptyState";

export default {
  name: 'HomeFrm',
  components: {
    AddFlat,
    Popup,
    EmptyState
  },
  beforeMount() {
    this.getApartmentsInfo();
  },
  data() {
    return {
      apartments: [],
      isOpenFlatPopup: false,
      currentId: 0,
      showEmptyState: false,
      isHouseEditing: false,
      currentHouse: {}
    }
  },
  computed: {
    ...mapState(['user']),
    isEditing: {
      get() {
        return this.$route.meta.role === 'Editing';
      }
    },
  },

  methods: {
    async getApartmentsInfo() {
      if (this.isEditing) {
        await User.getViewedUserApartment(this.$route.params.id).then(data => {
          this.apartments = data;
        })
        if (!this.apartments.length) {
          this.showEmptyState = true;
        }
      } else {
        this.apartments = this.user.apartments
        if (this.apartments.length === 0) {
          this.showEmptyState = true;
          this.openPopup();
        }
      }
    },
    getType(item) {
      switch (item.type || item.apartment?.type) {
        case 'parkinglot' :
          return 'Машиноместо';
        case 'apartment':
          return 'Квартира';
        case 'office':
          return 'Нежилое помещение';
        default :
          return '';
      }
    },
    openPopup() {
      this.isHouseEditing = false;
      this.currentHouse = {};
      this.isEditing = false;
      this.isOpenFlatPopup = true;
    },
      async add() {
          if (this.isEditing) {
              await User.getViewedUserApartment(this.$route.params.id).then(data => {
                  this.apartments = data;
              });
          } else {
              await User.getUserApartment();
              this.apartments = this.user.apartments
          }
          this.closePopup();
      },
    closePopup() {
      this.isOpenFlatPopup = false;
    },
    editHouse(item) {
      this.isOpenFlatPopup = true;
      this.isHouseEditing = true;
      this.currentHouse = item

    },
  },
  watch: {
    "$route.name": function () {
      this.getApartmentsInfo();
    },
    "apartments.length": function () {
      if (!this.apartments.length) {
        this.showEmptyState = true;
      } else {
        this.showEmptyState = false;
      }
    }
  },

}
</script>
<style lang="scss">
//.tooltip-gray:hover::after {
//  left: initial;
//}
</style>
